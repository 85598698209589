import { useEffect } from "react";

function ArticleDebug ( props ){
	
	useEffect(() => {
        //
	},[])

    const styleItem = {
        margin: '0 0 0 15px',
        width: '40px',
        fontSize: '10px',
        fontWeight: 700,
        LetterSpacing: '0',
        color: '#666',
    }

	return (
		<div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            // width: '200px',
            padding: '3px',
            // display: 'flex',
            display: 'none',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'space-around',
            fontSize: '10px !important',
        }}> 

			<small style={styleItem}>
                TAGS: {props.section.tags}
            </small>

			<small style={styleItem}>
                DISPLAY: {props.section.display_type}
            </small>
			
            <small style={styleItem}>
                CSS: {props.section.css_selector}
            </small>
			
		</div>
	);

}

export default ArticleDebug;