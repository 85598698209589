import {Fragment, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import axios from 'axios';
import { apiUrl } from '../Helpers/General';




function Contact(props){

	
	// eslint-disable-next-line
	const [data, setData] = useState([]);
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		msg: '',
	});
	const [isValid, setIsValid] = useState(false);
	const [isSent, 	setIsSent] = useState(null);
	const [isSending, 	setIsSending] = useState(false);


	useEffect(() => {

		const pageSlug = (props.lang==='hr') ? 'kontakt' : 'contact';

		axios.get(`${apiUrl}${props.lang}/api/pages/${pageSlug}`)
                .then(res => res.data)
                .then(data => {
                    setData( data[0]?.translations?.[0] );
                });

	// eslint-disable-next-line
	},[props])

	useEffect(() => {
		doValidate();
	// eslint-disable-next-line
	}, [formData])



	const updateField = (field, value) => {
		setFormData(
			{...formData, [field]:value}
		);
	}

	const doValidate = () => {
		let isFormValid = true;

		if( formData.name === '' ){
			isFormValid = false;
		}
		if( formData.email === '' || formData.email.includes('@') === false ){
			isFormValid = false;
		}
		if( formData.msg === '' ){
			isFormValid = false;
		}

		setIsValid( isFormValid )
	}

	const doSend = () => {

		setIsSending(true);

		axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
		setIsSent( null );

		axios.post(
			`${apiUrl}api/emailer`,
			formData
		)
			.then(res => res.data)
			.then(data => {
				setIsSent( data.success );
				setIsSending(false);
			})
			.catch(err => {
				setIsSent( false );
				setIsSending(false);
				console.log(err);
			})
	}

	

	return (
		<Fragment>
			
			<div className="page page--contact" >
                
				{ data?.title !== undefined && data.title !== '' && 
					<h1>{data.title}</h1>
				}

				{ data?.undertitle !== undefined && data.undertitle !== '' && 
					<h2>{data.undertitle}</h2>
				}

				{ data?.subtitle !== undefined && data.subtitle !== '' && 
					<h3>{data.subtitle}</h3>
				}

				{ data?.description !== undefined && data.description !== '' && 
					<p dangerouslySetInnerHTML={{__html: data.description}} />
				}

				<NavLink 
					to={`/${props.lang}/page/linktree`}
				>
					LinkTree
				</NavLink>

				<br />
				<br />
				<br />

				{ isSent === true && 
					<p>Thank you for your message.</p>
				}

				{ ( isSent === null || isSent === false) && 
					<Fragment>

						<div className="form">
							<fieldset>
								<label>name:</label> 
								<input type="text" onChange={(e) => updateField('name', e.target.value)} />
							</fieldset>
							
							<fieldset>
								<label>email:</label>
								<input type="email" onChange={(e) => updateField('email', e.target.value)} />
							</fieldset>

							<fieldset>
								<label>msg:</label> 
								<textarea rows={3}  onChange={(e) => updateField('msg', e.target.value)}></textarea>
							</fieldset>

							<fieldset>
								<label></label> 
								<button 
									disabled={ !isValid }
									className={`btn ${(isValid)?'btn--valid':'btn--invalid'}`}
									onClick={() => doSend()}
								>
									{ (isSending === false) 
										? (isSent === true) 
											? 'Message sent' 
											: 'Send Message'
										: 'Sending message' 
									}
								</button>
							</fieldset>
						</div>

						

						{ isSent === false && 
							<p>There was an error, please try again...</p>
						}
					</Fragment>
				}


			</div>
		
		</Fragment>
	);

}

export default Contact;