import {Fragment, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { motion } from "motion/react";
import { apiUrl, cdnUrl, cdnParams } from '../Helpers/General';
import { translations } from "../Helpers/Lang";
import { detailsSlugUrlLimiter } from "../Helpers/Ui";
import Loader from "../Components/Loader";
// import SectionPropagator from '../Components/SectionPropagator';
import ArticleCategory from "../Components/Elements/ArticleCategory";
// import ArticlePropagator from "../Components/ArticlePropagator";
import Media from "../Components/Media";


function Category(props){

	const [category, 	setCategory] = useState( null );
    const [items, 	    setItems] = useState( [] );

    const [isLoading,       setIsLoading] = useState(false);



	useEffect(() => {
		window.scrollTo(0, 0);

		if( props.lang !== undefined && props.lang !== '' && props.listLink.slug !== '' ){
            setIsLoading(true);
			axios.get(`${apiUrl}${props.lang}/api/lists/${props.listLink.slug}`)
				.then(res => res.data)
				.then(resData => {
                    setCategory(resData);
                    setIsLoading(false);
				})
                .catch(err => {
                    console.log(err)
                    setIsLoading(false);
                })
            axios.get(`${apiUrl}${props.lang}/api/items/${props.listLink.slug}?sub=true`)
				.then(res => res.data)
				.then(resData => {
                    setItems(resData);
                    setIsLoading(false);
				})
                .catch(err => {
                    console.log(err)
                    setIsLoading(false);
                })
		}
	// eslint-disable-next-line
	},[props.lang, props.listLink])


	

	return (
		<Fragment>

            { isLoading === true && 
                <Loader />
            }

            { isLoading === false && category !== null &&
                <div className="page page--category">

                    <div className="category__head">

                        <ArticleCategory 
                            lang={props.lang} 
                            list={ category[0] } 
                            lists={props.lists} 
                            baseClass="breadcrumbs--category-head"
                            section={ Object.assign({...props.listLink},{list_id: props.listLink.id}) } 
                        >
                            <NavLink 
                                className="breadcrumbs__item"
                                to={`/${props.lang}/category/${category[0].translations?.[0].slug}`}
                            >
                                {category[0].translations?.[0].title}
                            </NavLink>
                        </ArticleCategory>
                        

                    </div>

                    <div className="category__items">
                        {items.map( (item,itemIdx) => {

                            {/*
                            <SectionPropagator 
                                sectionIdx={itemIdx}
                                key={`section_${itemIdx}`}
                                lang={props.lang}
                                section={item} 
                                //lists={props.lists}
                                setDetailsContent={props.setDetailsContent}
                                showMoreButton={true}
                            />
                            */}

                        

                            return <motion.div 
                                initial={{ 
                                    opacity: 0,
                                    x: -40,
                                }}
                                whileInView={{
                                    x: [-40, 0],
                                    opacity: 1,
                                    transition: { 
                                        stiffnes: .2,
                                        duration: .25,
                                        //delay: itemIdx*0.2,
                                        ease: [0, 0.11, 0.2, 1.01],
                                    }
                                }}
                                viewport={{ 
                                    once: true,
                                    amount: .25,
                                }}
                                key={`category_item_${itemIdx}_${item.translations[0]?.slug}`}
                                className="category__item"
                            >
                                <ArticleCategory 
                                    lang={props.lang} 
                                    list={item.list} 
                                    lists={props.lists} 
                                    section={item} 
                                    hideParentsOfListId={props.listLink.id}
                                    baseClass="breadcrumbs--category-item"
                                />

                                <NavLink to={`#${item.translations[0]?.slug}${detailsSlugUrlLimiter}${item.id}`} data-cat-btn>
                                    <h2>
                                        {item.translations[0]?.title}
                                    </h2>
                                    <h3>
                                        {item.translations[0]?.subtitle}
                                    </h3>
                                

                                    { item.translations[0]?.media.length > 0 && 1===2 && 
                                        <Media 
                                            media={item.translations[0].media[0]} 
                                            cdnParams="&width=1200&height=1200&func=crop"
                                        />
                                    }
                                </NavLink>

                            </motion.div>

                        })}

                        {items.length === 0 && 
                            <h5>{translations[props.lang].no_content}</h5>
                        }
                    </div>

                </div>
            }

		</Fragment>
	);

}

export default Category;