import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { translations } from "../../Helpers/Lang";
import { detailsSlugUrlLimiter } from "../../Helpers/Ui";

function ArticleShowMore ( props ){
    
    // const location = useLocation();

    useEffect(() => {
        //
    },[])

    return (
        <NavLink to={`#${props.slug}${detailsSlugUrlLimiter}${props.id}`}>
            {translations[props.lang].read_more}
        </NavLink>
    );

}

export default ArticleShowMore;