import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from 'react-swipeable';
import { detailsSlugUrlLimiter } from '../Helpers/Ui';


function RouterWrapper(props) {

	const location = useLocation();

	const navigate = useNavigate();

	useEffect(() => {
        props.setMenuVisibility(false);
        props.setDetailsContent(location.hash);

		// dom slug
		if( props.mainRef.current !== null ){

			const urlSegments = window.location.href
				.split('/');
				
			const hashSegments = location.hash
				.replace("#", "")
				.split(detailsSlugUrlLimiter)
				.filter(x => x.length > 0)

			if( hashSegments.length <= 0 ){
				props.mainRef.current.dataset.type = urlSegments?.[4] || "page";
				props.mainRef.current.dataset.slug = urlSegments?.[5] || "home";
			}else{
				props.mainRef.current.dataset.type = "detail";
				props.mainRef.current.dataset.slug = hashSegments[0] || "";
			}
		}
	// eslint-disable-next-line
	}, [location.pathname, location.hash])


	 const swipeHandlers = useSwipeable({
			onSwipedLeft: (eventData) => {
				if( document.querySelector('.sidebar') !== null 
					&& (document.querySelector('.sidebar') === eventData.event.target || document.querySelector('.sidebar').contains(eventData.event.target) ) 
				){
					props.setMenuVisibility(false);
				}
				
				if( document.querySelector('.details') === eventData.event.target 
					|| document.querySelector('.details').contains(eventData.event.target)
				){
					props.setDetailsContent("");
					navigate(-1);
				}
			},
			preventScrollOnSwipe: true,
			trackMouse: false,
		});



	return <div className="wrapper" {...swipeHandlers} >

		{props.children}

	</div>
		
}
	
export default RouterWrapper;