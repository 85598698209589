import { useEffect } from "react";
import { motion } from "motion/react"
import ArticlePropagator from "../ArticlePropagator";
import ArticleCategory from "../Elements/ArticleCategory";


function SectionDetailsOnClick( props ){

	useEffect(() => {
		// 		
	},[])


    const translationIdx = 0;
    const translation = props.section.translations[translationIdx];

	return (
		<motion.div className={`section section--${props.section.display_type || 'default'} section-idx--${props.sectionIdx} ${props.section.css_selector}`} 
			initial={{ 
				opacity: 0,
				filter: "blur(20px)",
			}}
			animate={{
				x: 0,
				opacity: 1,
				filter: "none",
				transition: { 
					stiffnes: .2,
					duration: .1,
					delay: (props.sectionIdx+1) * .1,
					ease: [0, 0.11, 0.2, 1.01],
				}
			}}
		>

			<ArticleCategory {...props}/>

            <ArticlePropagator
                key={`article_${translation.id}`}
                translation={translation}
				articleIdx={0}
                addClass={`${(props.sectionIdx === 0 && translationIdx === 0)?'article--first':''}`}
                showMoreButton={true}
                {...props}
            />


			{/*props.section.translations.map( (translation,translationIdx) => 

				<ArticlePropagator
					key={`article_${translation.id}`}
					translation={translation}
					addClass={`${(props.sectionIdx === 0 && translationIdx === 0)?'article--first':''}`}
					showMoreButton={(props.sectionIdx === 0 && translationIdx === 0)?true:false}
					{...props}
				/>

			)*/}

		</motion.div>
	);

}

export default SectionDetailsOnClick;