import {useEffect, Fragment} from "react";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "motion/react"
import { ReactSVG } from 'react-svg'
import { languages } from "../Helpers/Lang";
import { sidebarCategoriesNesting } from "../Helpers/Ui";



function Sidebar(props){


	useEffect(() => {
        
	},[props])



    const renderPlaceholder = (placeholder) => {
        if( placeholder === '{categories}' ){
            return _renderCategories(
                props.lists.filter(x => x.parent_id === "0" ), 
                1, 
                sidebarCategoriesNesting
            );
        }
    }

    const _renderCategories = ( categories, displayLevel, levelsMax = 99 ) => {

        return categories
            .map( (list, listIdx) => 
                <div key={`home_cat_${listIdx}`} data-sub-level={displayLevel}>

                    <NavLink 
                        key={`sidebar_${listIdx}_${list.slug}`}
                        to={`/${props.lang}/category/${list.slug}`}
                    >
                        {list.translations[0]?.title}
                    </NavLink>

                    { displayLevel < levelsMax &&
                        <Fragment>
                            {_renderCategories( 
                                props.lists.filter(x => x.parent_id === list.id), 
                                displayLevel + 1,
                                levelsMax,
                            )}
                        </Fragment>
                    }

                </div>
        )
    }

    const renderLanguages = () => {
        return <div>
            {languages.length > 1 && 
                languages
                    .map( (language, langIdx) => 
                    <button 
                        key={`lang_${langIdx}`}
                        onClick={() => props.switchLang(language)}
                        className={` ${(props.lang === language) ? 'highlight' : ''}`}
                    >

                        {language}
                    </button>
            )}
        </div>
    }



	return (
        <Fragment>

            { props.isMenuVisible === false && 
                <button 
                    className="sidebar-toggle"
                    onClick={() => props.setMenuVisibility( !props.isMenuVisible )}
                >
                    <ReactSVG src="/assets/menu.svg" />
                </button>
            }

            <AnimatePresence>
                {  (props.lists.length > 0 && props.menu.length > 0 )
                    && ( props.isMenuVisible === true || props.isMobile === false ) 
                    && <motion.div 
                        className={`sidebar`}
                        initial={{ 
                            opacity: 0,
                            x: '-100vw',
                        }}
                        animate={{
                            x: 0,
                            opacity: 1,
                            transition: { 
                                stiffnes: .2,
                                duration: .2,
                                // delay: .5,
                                ease: [0, 0.11, 0.2, 1.01],
                            }
                        }}
                        exit={{
                            opacity: 0,
                            x: '-100vw',
                            transition: {
                                duration: .1,
                            }
                        }}>

                        <div className="wrapper">

                            <NavLink to={`/`}>
                                <ReactSVG src="/assets/menu_open.svg" />
                            </NavLink>

                            <AnimatePresence>           
                                {props.menu !== undefined && props.menu
                                    .filter( x => 
                                        x.slug !== '' 
                                        && x.in_menu === "1" 
                                        && ( 
                                            x?.tags === undefined || x?.tags.includes('is_hidden') === false 
                                        )
                                    )
                                    .map( (menuLink, pageIdx) => 
                                        ( menuLink.tags.includes('placeholder') )
                                            ? <motion.span
                                                key={`sidebar_${pageIdx}_${menuLink.slug}`}
                                                initial={{ 
                                                    opacity: 0,
                                                    x: '-10px',
                                                }}
                                                animate={{
                                                    opacity: 1,
                                                    x: 0,
                                                    transition: { 
                                                        stiffnes: .2,
                                                        duration: .2,
                                                        delay: pageIdx * .15,
                                                        ease: [0, 0.11, 0.2, 1.01],
                                                    }
                                                }}
                                            >
                                                {renderPlaceholder(menuLink.slug)}
                                            </motion.span>
                                            : <motion.span
                                                key={`sidebar_${pageIdx}_${menuLink.slug}`}
                                                initial={{ 
                                                    opacity: 0,
                                                    x: '-10px',
                                                }}
                                                animate={{
                                                    opacity: 1,
                                                    x: 0,
                                                    transition: { 
                                                        stiffnes: .2,
                                                        duration: .2,
                                                        delay: pageIdx * .15,
                                                        ease: [0, 0.11, 0.2, 1.01],
                                                    }
                                                }}
                                            >
                                                <NavLink 
                                                    to={`/${props.lang}/page/${menuLink.slug}`}
                                                >
                                                    {menuLink.title}
                                                </NavLink>
                                            </motion.span>
                                    )
                                }
                            </AnimatePresence>

                            {renderLanguages()}

                        </div>

                    </motion.div>
                }
            </AnimatePresence>

        </Fragment>

	);

}

export default Sidebar;