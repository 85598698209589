import { Fragment, useEffect, useState } from "react";
// import {Howl} from 'howler';
import WaveSurfer from 'wavesurfer.js'


import { mediaUrl, cdnUrl, cdnParams } from '../Helpers/General';
import Loader from "./Loader";

function Media( props ){

	const [ mediaElem, setMediaElem] = useState(null);
	const [ mediaType, setMediaType] = useState("");

	const [ isLoading, setIsLoading] = useState(false);


	const renderFooter = () => {
		const mediaParams = JSON.parse( props.media.params );
		const cdnPath = `${cdnUrl}${props.media.path}${cdnParams}${props.cdnParams || ''}`;

		return <div className="media-item__footer">

			{ mediaParams.hasOwnProperty('lightbox') && parseInt(mediaParams.lightbox) === 1 &&
				<span 
					data-lightbox
					data-lightbox-src={cdnPath}
					data-lightbox-title={(mediaParams.title !== '' && mediaParams.hasOwnProperty('title') && parseInt(mediaParams.title) === 1) ? props.media.title : ''}
				>
					EXPAND
				</span>
			}

			{ props.thumb !== true && mediaParams.title !== '' && mediaParams.hasOwnProperty('title') && parseInt(mediaParams.title) === 1 &&
				<p>{props.media.title}</p>
			}

		</div>
	}


	useEffect(() => {

		const cdnPath = `${cdnUrl}${props.media.path}${cdnParams}${props.cdnParams || ''}`;
		const mediaPath = `${mediaUrl}${props.media.path}`;
		const mediaParams = JSON.parse( props.media.params );

		// ----- embeds
		if( 	props.media.embed !== '' ){
			setMediaType('embed');
			setMediaElem( 
				<Fragment>
					<div 	className="media-item" 
							dangerouslySetInnerHTML={{__html:props.media.embed}} 
					/> 

					{renderFooter()}
				</Fragment>
			);
		}

		// ----- audio
		else if( 	props.media.path.includes('.wav') 
					|| props.media.path.includes('.mp3') 
		){
			setMediaType('audio');
			setMediaElem( 
				<AudioElem 
					mediaPath={mediaPath} 
					mediaId={props.media.id}
					footer={renderFooter()} 
				/>
			);
		}

		// ----- image
		else if( 	props.media.path.includes('.jpg') 
					|| props.media.path.includes('.jpeg') 
					|| props.media.path.includes('.png') 
					|| props.media.path.includes('.gif') 
		){
			if( props.loader ){
				setIsLoading(true);
			}

			setMediaType('image');
			setMediaElem( 
				<Fragment>
					<img 	className="media-item" 
							src={cdnPath} 
							alt={props.media.title}
							// onLoad={null}
							ref={(input) => {
								if (!input) { return; }
								input.addEventListener('load', (e) => {
									setIsLoading(false);
								}, {once: true});
								input.addEventListener('error', (e) => {
									setIsLoading(false);
								}, {once: true});
							}}
							loading={ (props.lazyload === true) ? "lazy" : "eager" }
							width="auto"
							height="100%"
					/>

					{renderFooter()}
				</Fragment>
			);
		}

		// ----- documents
		else if( 	props.media.path.includes('.zip') 
					|| props.media.path.includes('.pdf') 
					|| props.media.path.includes('.doc') 
					|| props.media.path.includes('.docx') 
					|| props.media.path.includes('.ppt') 
		){
			setMediaType(
				( mediaParams.hasOwnProperty('lightbox') && parseInt(mediaParams.lightbox) === 1 ) ? 'lightbox' : 'download'
			);
			setMediaElem( 
				<Fragment>
					{ parseInt(mediaParams.download) === 1 && 
						<a href={mediaPath} download target="_blank" rel="noreferrer">
							<div className="media-item__icon">
								DOWNLOAD
							</div>

							{renderFooter()}
						</a>
					}
				</Fragment>
			);
		}

		// ----- unknown
		else{
			setMediaType('unknown');
			setMediaElem( 
				<span>
					{props.media.path}
				</span>
			);
		}

	// eslint-disable-next-line
	}, [props.media])


	return (
		<div 
			className={`media media--${mediaType} media--loading-${isLoading} `} 
			key={`media_${props.media.id}`}>

			{ props.loader === true && isLoading === true && 
				<span>REFRESH</span>
			}

			{ mediaElem !== null &&
				mediaElem
			}
			
		</div>
	);

}


function AudioElem(props){

	const [audioObj, setAudioObj] = useState(null);
	// const [playing, setPlaying] = useState(false);
	const [duration, setDuration] = useState(0)
	const [percentage, setPercentage] = useState(0)
	const [isLoading, setIsLoading] = useState(false);

  	
	useEffect(() => {

		if( audioObj === null ){
			const waveObj = WaveSurfer.create({
				container: `#media_${props.mediaId}`,
				waveColor: '#222',
				progressColor: '#f00',
				url: props.mediaPath,
				cursorWidth: 3,
				barWidth: 3,
				barGap: 1,
				height: 40,
				interact: false,
				mediaControls: false,
				splitChannels: false,
			});
			waveObj.on('load', (percent) => {
				setIsLoading(true);
			});
			waveObj.on('loading', (percent) => {
				setPercentage(percent)
			});
			waveObj.on('ready', (duration) => {
				//
			});
			waveObj.on('redrawcomplete', (percent) => {
				setIsLoading(false);
			});
			waveObj.on('finished', () => {
				audioObj.stop();
			});
			setAudioObj(waveObj);
		}
		
		return () => {
			if( audioObj !== null && audioObj !== undefined && audioObj.isPlaying() ){
				audioObj.stop();
				audioObj.destroy();
				setAudioObj(null);
			}
		}

	// eslint-disable-next-line
	},[]);

	return (
		<div style={{
				position: 'relative',
				width: 'calc(100% - 50px)',
				display: 'flex',
				flexDirection: 'column',
				margin: '10px 0',
				padding: '10px 25px',
				background: '#fff',
				position: 'relative',
				border: '4px solid #222',
				color: '#222',
				cursor: 'pointer',
			}}
			id={`media_${props.mediaId}`}
			onClick={() => {
				if( audioObj !== null && audioObj !== undefined){
					if( audioObj.isPlaying() ){
						audioObj.pause();
					}else{
						audioObj.play()
					}
				}
			}}
		>

			{props.footer}

			{ isLoading === true && <Loader className="loader--absolute" /> }

			{ percentage < 100 && 
				<div style={{
					position: 'absolute',
					zIndex: 201,
					bottom: 0,
					left: 0,
					background: '#f00',
					height: '5px',
					width: `${percentage}%`,
					transform: 'all .5s ease-out',
				}}/>
			}

		</div>
	)

}


export default Media;