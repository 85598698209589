import { useEffect } from "react";
import { AnimatePresence, motion } from "motion/react";


function Loader ( props ){
    
    useEffect(() => {
        // 
    },[])

    return (
        <AnimatePresence>
            <motion.div className={`loader ${props.className || ''}`}
                initial={{ 
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        delay: .15,
                    }
                }}
                exit={{
                    opacity: 0,
                }}
            >
            </motion.div>
        </AnimatePresence>
    );

}

export default Loader;