import { useState, useRef, useEffect } from "react";
import { motion } from "motion/react";
import Media from '../Media';
import ArticleShowMore from "../Elements/ArticleShowMore";
import ArticleDebug from "../Elements/ArticleDebug";


function ArticleText ( props ){

	const detailsRef = useRef();
	const descriptionRef = useRef();
	const itemsRef = useRef({});

	const [ detailsVisible, setDetailsVisible ] = useState(true);


	useEffect(() => {
		
	},[])


	return (
		<motion.div 	className={`article article--${props.article.content_display || 'default'} article-idx--${props.articleIdx} ${props.addClass || ''} ${(detailsVisible && 'article--active')}`}
			initial={{ 
				x: 0,
				opacity: 0,
			}}
			animate={{
				x: 0,
				opacity: 1,
				transition: { 
					duration: .2,
					delay: (props.articleIdx+1) * .2,
					ease: [0, 0.71, 0.2, 1.01],
				}
			}}
			exit={{
				opacity: 0
			}}
		> 

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

			<ArticleDebug section={props.section} />

			{props.showMoreButton && 
				<ArticleShowMore 
					id={props.section.id} 
					slug={props.section.translations[0]?.slug} 
					lang={props.lang}
				/>
			}

			{ props.article.title !== '' && 
				<h2 id={props.article.slug}
					onClick={() => { setDetailsVisible( !detailsVisible ) }}
				>
					{props.article.title}
				</h2>
			}

			<div 
				ref={detailsRef}
				style={ (detailsVisible) ? {display: 'block'} :{display: 'none'} } 
			>

				{ props.article.subtitle !== '' && 
					<h3>{props.article.subtitle}</h3>
				}

				{/*<pre>{JSON.stringify(props.article)}</pre>*/}

				{ props.article.media.length > 0 && 
					<div className="article__media">
						{props.article.media.map( (media, mediaIdx) => 
							<div key={`article_media_${mediaIdx}_${media.id}`} ref={el => itemsRef.current[`media_${mediaIdx}_${media.id}`] = el}>
								<Media 
									key={`media_${mediaIdx}`} 
									media={media} 
									cdnParams="&width=600&func=crop"
								/>
							</div>
						)}
					</div>
				}
				
				{ props.article.description !== '' && 
					<p ref={descriptionRef} dangerouslySetInnerHTML={{__html: props.article.description}} />
				}

			</div>
			
		</motion.div>
	);

}

export default ArticleText;