import {Fragment, useEffect} from "react";
import { NavLink } from "react-router-dom";
import { 
	defaultLang, 
	translations 
} from '../Helpers/Lang';


function NotFound(props){

	useEffect(() => {
		//
	// eslint-disable-next-line
	},[])


	const lang = (props?.lang !== undefined) 
		? props.lang 
		: defaultLang;

	return (
		<Fragment>
		
			<div className="page page--not-found">
			
				<h1>{translations[lang].not_found}</h1>
				<p>{translations[lang].not_found_content}</p>

				<NavLink to={`/`}>{translations[lang].title}</NavLink>

			</div>

		</Fragment>
	);

}

export default NotFound;