export const defaultLang = 'en';

export const languages = [
	'en',
];

export const translations = {

	"hr" : {
		"title":					"N/OBE",
		"title_short":				"N/OBE",
		"subtitle":					"",
		"description":				"",
		"read_more":				"Više",
		"read_less":				"Zatvori",
		"back":						"Povratak",
		"prev":						"Prethodno",
		"next":						"Slijedeće",
		"not_found":				"Upsss",
		"not_found_content":		"Stranica nije nađena",
		"no_content":				"Nema sadržaja",
		"loading": 					"Učitavanje sadržaja...",
	},

	"en" : {
		"title":					"N/OBE",
		"title_short":				"N/OBE",
		"subtitle":					"",
		"description":				"",
		"read_more":				"More",
		"read_less":				"Close",
		"back":						"Back",
		"prev":						"Previous",
		"next":						"Next",
		"not_found":				"Oupsss",
		"not_found_content":		"Page not found",
		"no_content":				"No content",
		"loading": 					"Loading content...",
	},

}