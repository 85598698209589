import { useState, useEffect} from "react";
import axios from "axios";
import { NavLink, useLocation/*, useNavigate*/ } from "react-router-dom";
import { motion, AnimatePresence } from "motion/react"
import { apiUrl } from "../Helpers/General";
import ArticlePropagator from "./ArticlePropagator";
import ArticleCategory from "./Elements/ArticleCategory";
import ArticleHero from "./Elements/ArticleHero";
import Loader from "./Loader";
import { translations } from "../Helpers/Lang";

function Details(props){

    const [item,        setItem] = useState(null);
    const [itemPrev,    setItemPrev] = useState(null);
    const [itemNext,    setItemNext] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [isLoading,   setIsLoading] = useState(false);

    const location = useLocation();

    // const navigate = useNavigate();


    useEffect(() => {

        return () => {
            setItem(null);
            setItemPrev(null);
            setItemNext(null);
            setCurrentCategory(null)
        }
    }, [])


    useEffect(() => {       

        const hash = props.details.replace("#", "").split(":");
        if(hash[1] !== "" && hash[1] !== undefined){

            // set item
            setIsLoading(true);
            axios.get(`${apiUrl}${props.lang}/api/item/${hash[1]}`)
                .then(res => res.data)
                .then(resData => {
                    setItem(resData);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.log(err)
                    setIsLoading(false);
                })

            // set current category
            const urlSegments = window.location.href.split('/');
            try{
                const cat = urlSegments?.[5].split("#")[0];
                if( cat !== undefined ){
                    const catTitle = props.lists.find(x => x.slug === cat)?.translations[0].title;
                    setCurrentCategory( (catTitle !== undefined && catTitle !== null) 
                        ? `Back to ${catTitle}`
                        : translations[props.lang].back
                    );
                }
            }
            catch(err){
                console.log('no such category')
            } 

        }
    // eslint-disable-next-line
    },[props.details])

    useEffect(() => {
        if( item !== null ){

            const catItems = [...document.querySelectorAll('[data-cat-btn]')];
            const currItemIdx = catItems.findIndex(x => x.getAttribute('href').includes(props.details) );
            // prev
            if( catItems?.[currItemIdx-1] !== undefined ){
                setItemPrev({
                    slug: catItems[currItemIdx-1].getAttribute('href'),
                    title: catItems[currItemIdx-1].querySelector('h2').innerText
                });
            }else{
                setItemPrev( null );
            }
            // next
            if( catItems?.[currItemIdx+1] !== undefined ){
                setItemNext({
                    slug: catItems[currItemIdx+1].getAttribute('href'),
                    title: catItems[currItemIdx+1].querySelector('h2').innerText
                });
            }else{
                setItemNext( null );
            }


        }
    }, [item])

    useEffect(() => {
		if(location.hash === ""){
            setItem(null);
        }

        document.querySelector('.details').scrollTo(0,0)

	}, [location.hash])
    

    return (
        <div className={`details`}>

                { isLoading === true && 
                    <Loader />
                }

                <AnimatePresence>

                    {isLoading === false && props.details !== "" && props.lang !== "" && item !== null && 
                        <motion.div 
                            
                            className="wrapper"
                            initial={{ 
                                // y: '50px',
                                opacity: 0
                            }}
                            animate={{
                                // y: '0px',
                                opacity: 1,
                                transition: { 
                                    stiffnes: .2,
                                    duration: .4,
                                    ease: [0, 0.11, 0.2, 1.01],
                                }
                            }}
                            exit={{
                                opacity: 0,
                                // y: '-50px',
                                transition: {
                                    duration: .32,
                                }
                            }}
                        >

                            { item.translations.length > 0 && item.translations[0].media.length > 0 && 1===2 &&
                                <ArticleHero
                                    item={item.translations[0]}
                                />
                            }
                            
                        


                            <div className="double">

                                <div>
                                    {item.translations
                                        .filter( (x,idx) => idx === 0 )
                                        .map( (translation,translationIdx) => 

                                            <ArticlePropagator
                                                key={`article_${translation.id}_${translationIdx}`}
                                                translation={translation}
                                                section={item}
                                                addClass={`article--details article--media-carousel`}
                                                showMoreButton={false}
                                                {...props}
                                            />

                                    )}
                                </div>

                                <div>

                                    <ArticleCategory 
                                        lang={props.lang} 
                                        list={item.list} 
                                        lists={props.lists} 
                                        section={item} 
                                        baseClass="breadcrumbs--details"
                                    />

                                    <div className="ctas">

                                        { (itemPrev !== null) 
                                            ? <NavLink 
                                                className="ctas__prev"
                                                to={`${itemPrev.slug}`}
                                            >
                                                {/*translations[props.lang].prev.toUpperCase()*/} {itemPrev.title}
                                            </NavLink>
                                            : <NavLink 
                                                className="ctas__prev-up"
                                                to={`${window.location.href.split('#')[0]}`}
                                            >
                                                {currentCategory}
                                            </NavLink>
                                        }

                                        { (itemNext !== null) 
                                            ? <NavLink 
                                                className="ctas__next"
                                                to={`${itemNext.slug}`}
                                            >
                                                {/*translations[props.lang].next.toUpperCase()*/} {itemNext.title}
                                            </NavLink>
                                            : <NavLink 
                                                className="ctas__next-up"
                                                to={`${window.location.href.split('#')[0]}`}
                                            >
                                                {currentCategory}
                                            </NavLink>
                                        }

                                    </div>

                                    {item.translations
                                        .filter( (x,idx) => idx > 0 )
                                        .map( (translation,translationIdx) => 

                                            <ArticlePropagator
                                                key={`article_${translation.id}_${translationIdx}`}
                                                translation={translation}
                                                section={item}
                                                addClass={`article--details`}
                                                showMoreButton={false}
                                                {...props}
                                            />

                                    )}


                                    <div className="ctas">
                                        <NavLink 
                                            className="ctas__back"
                                            to={`${window.location.href.split('#')[0]}`}
                                        >
                                            Close
                                        </NavLink>
                                    </div>
                                    
                                </div>

                            </div>


                            {/*
                            <button 
                                onClick={() => navigate(-1)}
                                className="back"
                            >
                                {translations[props.lang]?.back}
                            </button>
                            */}


                        </motion.div>    
                    }

                </AnimatePresence>
            

        </div>
    );

}

export default Details;