import {Fragment, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import history from '../history';
import { apiUrl } from '../Helpers/General';

import SectionPropagator from '../Components/SectionPropagator';

// import Media from '../Components/Media';
import PageLinks from '../Components/PageLinks';
import Loader from "../Components/Loader";



function Page(props){

	const navigate = useNavigate();

	const [page, 			setPage] = useState( null );
	const [isLoading,       setIsLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);

		if( props.lang !== undefined && props.lang !== '' && props.menuLink.slug !== '' ){
			setIsLoading(true);
			axios.get(`${apiUrl}${props.lang}/api/pages/${props.menuLink.slug}`)
				.then(res => res.data)
				.then(data => {
					if( data.length <= 0 ){
						console.log(props.lang, history, navigate);
					}else{
						setPage(data[0]);
						setIsLoading(false);

						/*
						if( window.location.hash.replace('#','') !== '' ){
							setTimeout(() => {
								let hashScrollSelector = `[data-scroll-to-slug="${window.location.hash.replace('#','')}"]`;
								if( document.querySelector(hashScrollSelector) !== null ){
									document.querySelector(hashScrollSelector).scrollIntoView();
								}
							}, 500);
						}
						*/

					}
				})
				.catch(err => {
                    console.log(err)
                    setIsLoading(false);
                })
		}
	// eslint-disable-next-line
	},[props.lang, props.menuLink])


	

	return (
		<Fragment>

			{ isLoading === true && 
				<Loader />
			}

			{ isLoading === false && page !== null && 
				<Fragment>

					<div className={`page page--generic page--${page.slug}`}>

						<Fragment>
							<div className="page__content">
								<SectionPropagator 
									sectionIdx={0}
									key={`page_${page.slug}`} 
									lang={props.lang}
									section={page} 
									setDetailsContent={props.setDetailsContent}
									showMoreButton={false}
								/>
							</div>
							
							<PageLinks 
								page={page} 
							/>
						</Fragment>

					</div>

					
				</Fragment>
			}

		</Fragment>
	);

}

export default Page;