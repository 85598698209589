import { useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";


function ArticleCategory ( props ){
    
    useEffect(() => {
        // 
    },[])


    const renderParent = ( listId ) => {

        if( props.hideParentsOfListId !== undefined && props.hideParentsOfListId === listId ){
            return null
        }

        const cat = props.lists.find( x => x.id === listId )

        let json = null;
        if( cat !== null ){
            const parentCatJson = cat?.parent?.translations[0].content_json;
            try{
                json = JSON.parse(parentCatJson);
            }catch(err){
                json = null
            }
        }

        return <Fragment>

            {   json !== null 
                && cat?.parent?.id !== undefined 
                && renderParent(cat?.parent?.id) 
            }

            { json !== null && 
                <Fragment>
                    <NavLink 
                        className="breadcrumbs__item"
                        to={`/${props.lang}/category/${json.slug}`}
                    >
                        {json.title}
                    </NavLink>
                </Fragment>
            }

        </Fragment>
    }


    const categoryJson = ( props?.section.list !== undefined && props.section.list.length > 0 )
		? props.section.list[0]
		: null;



    return (
        <div className={`breadcrumbs ${props.baseClass} `}>

            { props?.lists !== undefined && 
                renderParent(props.section.list_id)
            }

            { categoryJson !== null && 
                <NavLink 
                    className="breadcrumbs__item"
                    to={`/${props.lang}/category/${categoryJson.slug}`}
                >
                    {categoryJson.title}
                </NavLink>
            }

            {props.children}

        </div>
    );

}

export default ArticleCategory;