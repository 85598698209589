import { Fragment, useEffect, useState } from "react";


import SectionDefault from 						'./Sections/SectionDefault';
import SectionDetailsOnClick from 				'./Sections/SectionDetailsOnClick';



function SectionPropagator( props ){

	const [component, setComponent] = useState(null);

	useEffect(() => {

		const sections = {
			default: 							SectionDefault,
			dts_grid2: 							SectionDetailsOnClick,
		};

		let Component = sections[ props.section.display_type ] || SectionDefault;
		setComponent( 
			<Component 
				key={`comp_${Math.random()}`} 
				section={props.section} 
				sectionIdx={props.sectionIdx} 
				{...props}
			/> 
		);

	// eslint-disable-next-line
	}, [props.section])

	// console.log("section", props.section)

	return (
		<Fragment>
		
			{/*<h6>-section-</h6>*/}

			{ component !== null && {...component} }

		</Fragment>
	);

}

export default SectionPropagator;