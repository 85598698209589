import { Fragment, useEffect, useState, useRef } from 'react';
import { 
    BrowserRouter, 
    Routes, 
    Route,
} from "react-router-dom";
import RouterWrapper from '../Containers/RouterWrapper';
import history from './../history';
import axios from 'axios';
import { initializeKinesis } from '@amineyarman/kinesis'

import { 
    apiUrl
} from '../Helpers/General';
import {
    defaultLang,
    languages,
} from '../Helpers/Lang';




import Home from            '../Pages/Home';
import Contact from         '../Pages/Contact';
import Page from            '../Pages/Page';
import Category from        '../Pages/Category';
import NotFound from        '../Pages/NotFound';

import Sidebar from         '../Components/Sidebar';
import Details from         '../Components/Details';
import Loader from          '../Components/Loader';



function Main() {

    const mainRef = useRef();

    const [lang, 	        setLang] = useState(null);
    const [lists, 	        setLists] = useState([]);
    const [menu, 	        setMenu] = useState([]);
    const [details,         setDetails] = useState("");

    const [isMenuVisible,   setIsMenuVisible] = useState(false);
    const [isLoading,       setIsLoading] = useState(false);
    const [isMobile,        setIsMobile] = useState(false);
    const [isInitialLoaded, setIsInitialLoaded] = useState(false);
    
    

    useEffect(() => {
        // set lang
        let urlSegments = window.location.href.split('/');
        let lang = ( urlSegments?.[3] !== undefined && urlSegments[3] !== '' && languages.includes(urlSegments[3].toLowerCase()) ) 
            ? urlSegments[3] 
            : defaultLang;
        switchLang( lang );

        // close sidebar
        document.addEventListener('click', (e) => {
            // close menu on click outside
            if( e.target !== null 
                && (
                    (
                        e.target.getAttribute('class') !== null
                        && e.target.getAttribute('class').includes('sidebar') === false
                    )
                    || 
                    (
                        e.target.tagName.toLowerCase() === "html"
                    )
                    || 
                    (
                        e.target.tagName.toLowerCase() === "body"
                    )
                )
                
                // && e.target.tagName.toLowerCase() !== "a"
            ){
                setIsMenuVisible(false);
            }

            // close details on click outside details
            if( e.target !== null 
                && e.target.tagName.toLowerCase() !== "a"
                && document.querySelector('.details') !== undefined
                && document.querySelector('.details') !== null
                && document.querySelector('.details').contains(e.target) === false
            ){
                window.location.hash = "";
                setDetailsContent("");
            }
        })

        // check if menu visible on mobile/tablet
        window.addEventListener(
            'resize', 
            checkDevice
        );

        // 
        initializeKinesis()

        return () => {
            window.removeEventListener('resize', checkDevice);
        }
    // eslint-disable-next-line
    },[])

    useEffect(() => {
        if( lang !== null && lang !== ""){
            setIsLoading(true);
            axios.get(`${apiUrl}${lang}/api/menus`)
                .then(res => res.data)
                .then(resData => {
                    setMenu(resData);
                    setIsLoading(false);
                });

            axios.get(`${apiUrl}${lang}/api/lists`)
                .then(res => res.data)
                .then(resData => {
                    setLists(resData);
                    setIsLoading(false);
                });
        }
    }, [lang])

    useEffect(() => {
        if( menu.length > 0 && lists.length > 0 ){
            setIsLoading(false);
            setIsInitialLoaded(true);
            checkDevice();
        }
    }, [menu,lists])
    
    useEffect(() => {
        mainRef.current.dataset.menu = isMenuVisible;
    }, [isMenuVisible])


    const switchLang = (lang) => {
        setLang(lang);
    }

    const setMenuVisibility = ( isOn = false ) => {
        setIsMenuVisible(isOn)
    }

    const setDetailsContent = ( data ) => {
        setDetails(data);
    }

    const checkDevice = () => {
        const isDeviceMobile = ( window.innerWidth >= 676 ) ? false : true;
        setIsMobile(isDeviceMobile);
        mainRef.current.dataset.isMobile = isDeviceMobile;
    }

   



    return (

        <div 
            ref={mainRef}
            className="main" 
        >

            { isLoading === true &&
                <Loader />
            }

            <BrowserRouter 
                history={history} 
            >

				<RouterWrapper 
                    mainRef={mainRef}
                    setMenuVisibility={setMenuVisibility}
                    setDetailsContent={setDetailsContent}
                >

                    <Fragment>

                        <Sidebar 
                            menu={menu}
                            lists={lists}
                            isMenuVisible={isMenuVisible}
                            setMenuVisibility={setMenuVisibility}
                            lang={lang}
                            switchLang={switchLang}
                            setDetailsContent={setDetailsContent}
                            isMobile={isMobile}
                        />

                        <div className="content">

                            <Routes>

                                <Route 
                                    exact 
                                    path="/" 
                                    element={ <Home lang={lang} isMobile={isMobile} /> }
                                />

                                { languages.map(language => 
                                    <Route 
                                        exact
                                        key={`menulink_${language}}`}
                                        path={`${language}`} 
                                        element={ <Home lang={language} isMobile={isMobile} />} 
                                    />
                                )}

                                <Route 
                                    exact
                                    path={`en/page/contact`}
                                    element={<Contact lang={lang} />} 
                                />
                                <Route 
                                    exact
                                    path={`hr/page/kontakt`}
                                    element={<Contact lang={lang} />} 
                                />

                                <Route 
                                    exact
                                    path={`*`}
                                    element={ (isLoading === false && isInitialLoaded === true) 
                                        ? <NotFound lang={lang} />
                                        : null
                                    } 
                                />

                                { [...menu
                                    .map( (menuLink,menuItemIdx) => 
                                        <Fragment key={`menulinks_${menuLink.slug}_${menuItemIdx}}`}>
                                            <Route 
                                                exact
                                                path={`${lang}/page/${menuLink.slug}`} 
                                                element={<Page menuLink={menuLink} lang={lang} setDetailsContent={setDetailsContent} />} 
                                            />
                                        </Fragment>
                                )]}

                                { [...lists
                                    .map( (listLink,listItemIdx) => 
                                        <Fragment key={`listlinks_${listLink.slug}_${listItemIdx}}`}>
                                            <Route 
                                                exact
                                                path={`${lang}/category/${listLink.slug}`} 
                                                element={<Category listLink={listLink} lists={lists} lang={lang} setDetailsContent={setDetailsContent} />}
                                            />
                                        </Fragment>
                                )]}

                            </Routes>

                            <Details 
                                details={details} 
                                lang={lang}
                                lists={lists}
                                setDetailsContent={setDetailsContent}
                            />

                        </div>
                        
                    </Fragment>

                </RouterWrapper>
            </BrowserRouter>

        </div>

    );
}
    
export default Main;