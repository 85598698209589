import {Fragment, useEffect, useState, useRef} from "react";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { motion } from "motion/react";
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import Media from "../Components/Media";
import { 
	apiUrl,
	cdnUrl,
	mediaUrl,
} from '../Helpers/General';
import { 
	homepageSlideHeroTag 
} from "../Helpers/Ui";
import Loader from "../Components/Loader";



function Home(props){

	// const parallax = useRef(null);

	const [data, 	setData] = useState([]);
	const [lists,   setLists] = useState([]);
	const [slideIdx, setSlideIdx] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {

		if( props.lang !== undefined && props.lang !== null ){
			const pageSlug = (props.lang==='hr') ? 'pocetna' : 'homepage';

			setIsLoading(true);

			axios.get(`${apiUrl}${props.lang}/api/pages/${pageSlug}`)
					.then(res => res.data)
					.then(resData => {
						setData( resData[0]?.translations );
						setIsLoading(false);
					});

			axios.get(`${apiUrl}${props.lang}/api/lists`)
					.then(res => res.data)
					.then(resData => {
						setLists(resData.filter(x => x.tags.includes('homepage')));
						setIsLoading(false);
					});
		}

	// eslint-disable-next-line
	},[props.lang])



	return (
		<Fragment>
			
			<div className="page page--home" >

			{ isLoading === true &&
				<Loader />
			}

			{ isLoading === false && data !== undefined && data.length > 0 && 
				<Fragment>

					<div className="slides">

						{data
							//.filter( (x,idx) => idx === slideIdx)
							.map( (dataItem,dataItemIdx) => {

							const videoMp4 = dataItem.media.find(x => 
								x.title === homepageSlideHeroTag 
								&& x.path.toLowerCase().includes('.mp4')
							) || null;
							
							const videoOgg = dataItem.media.find(x => 
								x.title === homepageSlideHeroTag 
								&& x.path.toLowerCase().includes('.ogg')
							) || null;

							const videoPoster = dataItem.media.find(x => 
								x.title === homepageSlideHeroTag 
								&& (
									x.path.toLowerCase().includes('.jpg') 
									|| x.path.toLowerCase().includes('.jpeg') 
									|| x.path.toLowerCase().includes('.png')
								)  
							) || null;

							return <div 
									key={`slide_${dataItemIdx}_${dataItem.slug}`}
									className={`slide ${(dataItemIdx === 0)?'slide--hero':'slide--content'}`}
									data-idx={dataItemIdx}
									data-kinesistransformer
								>

									{ dataItem?.title !== undefined && dataItem.title !== '' && 
										<h1 data-kinesistransformer-element
											data-ks-transform="translate"
											data-ks-strength="20"
											data-ks-interaction={(props.isMobile)?'scroll':'mouse'}
										>
											{dataItem.title.split('').map( (letter, letterIdx) => 
												<motion.span
													key={`word_${letterIdx}`}
													style={{ display: 'inline-block'}}
													initial={{ 
														opacity: 0,
														y: 10,
													}}
													whileInView={{
														opacity: 1,
														y: [10, -5, 0],
														// x: [50, 10, 0],
														transition: { 
															stiffnes: .01,
															duration: .5,
															delay: .05 * letterIdx,
															ease: [0, 0.71, 0.2, 1.01],
														}
													}}
													viewport={{ 
														once: true,
														amount: .5,
													}}
												>
													{letter}
												</motion.span>
											)}
										</h1>
									}

									{ dataItem?.undertitle !== undefined && dataItem.undertitle !== '' && 
										<h2	data-kinesistransformer-element
											data-ks-transform="translate"
											data-ks-strength="40"
											data-ks-interaction={(props.isMobile)?'scroll':'mouse'}
										>
											{dataItem.undertitle}
										</h2>
									}

									{ dataItem?.subtitle !== undefined && dataItem.subtitle !== '' && 
										<h5
											data-kinesistransformer-element
											data-ks-transform="translate"
											data-ks-strength="40"
											data-ks-interaction={(props.isMobile)?'scroll':'mouse'}
										>
											{dataItem.subtitle.split(' ').map((word,wordIdx) => 
											<motion.span
												key={`word_${wordIdx}`}
												style={{ display: 'inline-block'}}
												initial={{ 
													opacity: 0,
													y: 10,
												}}
												whileInView={{
													opacity: 1,
													y: [10, -5, 0],
													// x: [50, 10, 0],
													transition: { 
														stiffnes: .01,
														duration: .25,
														delay: .1 * wordIdx,
														ease: [0, 0.71, 0.2, 1.01],
													}
												}}
												viewport={{ 
													once: true,
													amount: .5,
												}}
											>
												{word}&nbsp;
											</motion.span>
										)}</h5>
									}

									{ dataItem.media.length > 0 && 
										<div className="article__media">
											{dataItem.media.filter(x => x.title !== homepageSlideHeroTag).map( (media, mediaIdx) => 
												<div key={`slide__media_${mediaIdx}_${media.id}`}>
													<Media 
														key={`media_${mediaIdx}`} 
														media={media} 
														cdnParams="&width=600&func=crop"
													/>
												</div>
											)}
										</div>
									}

									{ dataItemIdx === 0 && 
										<div className="breadcrumbs breadcrumbs--home">
											{lists.length > 0 && lists.map( (list,listIdx) => 
												<NavLink 
													className="breadcrumbs__item"
													key={`cat_${listIdx}`}
													to={`/${props.lang}/category/${list.slug}`}
												>
													{list.translations[0]?.title}
												</NavLink>
											)}
										</div>
									}
									{ dataItemIdx > 0 && 
										<Fragment>
											{ dataItem?.description !== undefined && dataItem.description !== '' && 
												<p dangerouslySetInnerHTML={{__html: dataItem.description}} />
											}
										</Fragment>
									}
									


									{ ( videoMp4 !== null || videoOgg !== null ) && 
										<video 
											data-kinesistransformer-element
											data-ks-transform="translate"
											data-ks-strength={30}
											className="hero" 
											autoPlay 
											loop
											muted 
											poster={ (videoPoster !== null) ? `${mediaUrl}${videoPoster?.path}` : '' }
										>
											{ videoMp4 !== null && 
												<source src={`${mediaUrl}${videoMp4.path}`} type="video/mp4" />
											}
											{ videoOgg !== null &&  
												<source src={`${mediaUrl}${videoOgg.path}`} type="video/ogg" />
											}
										</video>
									}

									{ videoPoster !== null && (videoMp4 == null && videoOgg === null ) && 
										<img 
											data-kinesistransformer-element
											data-ks-transform="translate"
											data-ks-strength={30}
											className="hero" 
											src={`${cdnUrl}${videoPoster.path}`} 
											alt="hero" 
										/>
									}

								</div>

						})}

					</div>

					
					<div className="pagination">

						{/*data
							.map( (itm, itmIdx) => 
								<button
									style={ (itmIdx === 0 && slideIdx === 0) ? {display: 'none'} : {} }
									key={`slide_btn_${itmIdx}`}
									className={`pagination-item ${(itmIdx === slideIdx) ? 'pagination-item--active' : 'pagination-item--inactive'}`}
									onClick={() => setSlideIdx(itmIdx)}
								>
									<h3>{itm.title}</h3>
								</button>
						)*/}
					</div>
					


				</Fragment>
			}	

			</div>
		
		</Fragment>
	);

}

export default Home;